.loader {
	background: rgba(0, 0, 0, .3);
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999999;
    
    &__content {
        display: block;
        width: 220px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -10px 0  0 -110px;
    }
}
