.action-icon,
a.action-icon {
    color: #0082c6;
    cursor: pointer;
    text-decoration: none !important;
    border: 0 !important;
}

.hidden-file {
    width: 0px;
    height: 0px;
    overflow: hidden;
}
